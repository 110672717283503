import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import "./referral.css"
import setting from "../../component/images/setbar.png";
import Modal from "react-modal";
import down from "../../component/images/down.png";

const Referral = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const deliveryData = [
        { name: 'Go Kada', phone: '090 5432 3213', deliveries: 1456 },
        { name: 'Mee Logistics', phone: '090 5432 3213', deliveries: 65 },
        { name: 'Mimi Logi', phone: '090 5432 3213', deliveries: 10 },
        { name: 'My Keke', phone: '090 5432 3213', deliveries: 5 },
        { name: 'Lanttern Log', phone: '090 5432 3213', deliveries: 5 },
        { name: 'High Load', phone: '090 5432 3213', deliveries: 5 },
        { name: 'Fast Reload', phone: '090 5432 3213', deliveries: 5 },
        { name: 'Speed Mover', phone: '090 5432 3213', deliveries: 5 },
        { name: 'Ade Bikes', phone: '090 5432 3213', deliveries: 5 },
        { name: 'Ola Logistics', phone: '090 5432 3213', deliveries: 5 },
        { name: 'Hot Spin', phone: '090 5432 3213', deliveries: 5 },
        { name: 'Fast Wheels', phone: '090 5432 3213', deliveries: 5 },
        { name: 'Mimi', phone: '090 5432 3213', deliveries: 5 },
    ];
    
    const userData = [
        { name: 'John Doe', phone: '090 5432 3213', deliveries: 112 },
        { name: 'Jane Smith', phone: '090 5432 3213', deliveries: 8 },
        { name: 'Alice Brown', phone: '090 5432 3213', deliveries: 15 },
    ];
    


  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    // Navigate to the RowDetails component with the rowData
    navigate(`/App/user_referral`, { state: { rowData } });
  };

  const handleRowClick1 = (rowData) => {
    // Navigate to the RowDetails component with the rowData
    navigate(`/App/agent_referral`, { state: { rowData } });
  };
  
  const [activeContent, setActiveContent] = useState('user'); // State to track which content is active

  const handleButtonClick = (content) => {
    setActiveContent(content); // Set the content based on the button clicked
  };
  

  const [value, setValue] = useState(1); 
  const handleValueChange = (newValue) => setValue(Math.max(0, Math.min(10, newValue)));

  const [value1, setValue1] = useState(1); 
  const handleValueChange1 = (newValue1) => setValue1(Math.max(0, Math.min(50, newValue1)));

  const [value2, setValue2] = useState(1); 
  const handleValueChange2 = (newValue2) => setValue2(Math.max(0, Math.min(200, newValue2)));

  const [value3, setValue3] = useState(10); 
  const handleValueChange3 = (newValue3) => setValue3(Math.max(10, Math.min(1000, newValue3)));


   
  return (
    <div className='referral'>
        <div className='referal-main'>
            <div className='referal-top'>
                <div className='referal-title'>
                    <h1>Referrals with downliners</h1>
                </div>
                <div className='referal-settings'>
                    <p>Settings</p>
                    <img
                        src={setting}
                        width="30px"
                        height="30px"
                        alt='icon'
                        onClick={openModal}
                    />
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Profile Modal"
                            className="referal-modal"
                            overlayClassName="referal-overlay"
                        >
                            <form className='referal-set-pop'>
                                <div className='pop-title'>
                                   <span onClick={closeModal}>&lt;</span>
                                    <p>Reward Settings</p>
                                </div>
                                <h1>(A) USER</h1>
                                <div className='pop-div'>
                                    <label>Number of target downliners</label>
                                    <div className="referal-input-container">
                                        <input
                                            type="number"
                                            min="1"
                                            max="10"
                                            value={value}
                                            onChange={(e) => handleValueChange(Number(e.target.value))}
                                            className="referal-input"
                                        />
                                        <img
                                            src={down}
                                            alt="Increase"
                                            width="24px"
                                            height="24px"
                                            className="referal-icon"
                                            onClick={() => handleValueChange(value + 1)}
                                        />
                                    </div>
                                </div>
                                <div className='pop-div'>
                                    <label>Targeted number of delivery orders</label>
                                    <div className="referal-input-container">
                                        <input
                                            type="number"
                                            min="1"
                                            max="50"
                                            value={value1}
                                            onChange={(e) => handleValueChange1(Number(e.target.value))}
                                            className="referal-input"
                                        />
                                        <img
                                            src={down}
                                            alt="Increase"
                                            width="24px"
                                            height="24px"
                                            className="referal-icon"
                                            onClick={() => handleValueChange1(value1 + 1)}
                                        />
                                    </div>
                                </div>
                                <h1>(A) Delivery Agent</h1>
                                <div className='pop-div'>
                                    <label>Target daily deliveries</label>
                                    <div className="referal-input-container">
                                        <input
                                            type="number"
                                            min="1"
                                            max="200"
                                            value={value2}
                                            onChange={(e) => handleValueChange2(Number(e.target.value))}
                                            className="referal-input"
                                        />
                                        <img
                                            src={down}
                                            alt="Increase"
                                            width="24px"
                                            height="24px"
                                            className="referal-icon"
                                            onClick={() => handleValueChange2(value2 + 1)}
                                        />
                                    </div>
                                </div>
                                <div className='pop-div'>
                                    <label>Target weekly deliveries</label>
                                    <div className="referal-input-container">
                                        <input
                                            type="number"
                                            min="10"
                                            max="1000"
                                            value={value3}
                                            onChange={(e) => handleValueChange3(Number(e.target.value))}
                                            className="referal-input"
                                        />
                                        <img
                                            src={down}
                                            alt="Increase"
                                            width="24px"
                                            height="24px"
                                            className="referal-icon"
                                            onClick={() => handleValueChange3(value3 + 1)}
                                        />
                                    </div>
                                </div>
                                <button>Save</button>
                            </form>
                        </Modal>
                </div>
            </div>
            <div className="referal-btn-ctn">
                <button
                    onClick={() => handleButtonClick('user')}
                    style={{
                        backgroundColor: activeContent === 'user' ? 'rgba(31, 170, 8, 1)' : 'transparent',
                        color: activeContent === 'user' ? 'rgba(255, 255, 255, 1)' : 'black',
                        padding: '0 2.3rem',
                        cursor: 'pointer',
                        marginRight: '1rem',
                        borderRadius: "3px",
                        transition: "all 0.3s ease"
                    }}
                >
                    User
                </button>
                <button
                    onClick={() => handleButtonClick('delivery agent')}
                    style={{
                        backgroundColor: activeContent === 'delivery agent' ? 'rgba(31, 170, 8, 1)' : 'transparent',
                        color: activeContent === 'delivery agent' ? 'rgba(255, 255, 255, 1)' : 'black',
                        padding: '0 1rem',
                        cursor: 'pointer',
                        marginRight: '1rem',
                        borderRadius: "3px",
                        transition: "all 0.3s ease"
                    }}
                >
                    Delivery Agent
                </button>
            </div>        
            <div className="content-container">
                {activeContent === 'user' &&         
                    <table className="referal-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Number of downliners</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.map((item, index) => (
                            <tr key={index} onClick={() => handleRowClick(item)} className='referral-row'>
                                <td>{item.name}</td>
                                <td>{item.phone}</td>
                                <td>{item.deliveries}</td>
                                <td
                                className={`${
                                    item.deliveries > 10 ? 'reward-due' : 'target-not-met'
                                }`}
                                >
                                {item.deliveries > 10 ? 'Reward Due' : 'Target Not Met'}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                }
                {activeContent === 'delivery agent' &&         
                    <table className="referal-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Number of downliners</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliveryData.map((item, index) => (
                            <tr key={index} onClick={() => handleRowClick1(item)} className='referral-row'>
                                <td>{item.name}</td>
                                <td>{item.phone}</td>
                                <td>{item.deliveries}</td>
                                <td
                                className={`${
                                    item.deliveries > 10 ? 'reward-due' : 'target-not-met'
                                }`}
                                >
                                {item.deliveries > 10 ? 'Reward Due' : 'Target Not Met'}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    </div>
  )
}

export default Referral