import React, { useState, useEffect } from "react";
import "../../component/css/individual_user.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import insdelivery from "../../component/images/insdelivery.png";
import cancelled from "../../component/images/cancelled.png";
import calender from "../../component/images/calender.png";
import aang from "../../component/images/aang.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import green from "../../component/images/green.png";
import red from "../../component/images/red.png";
import yellow from "../../component/images/yellow.png";
import orange from "../../component/images/orange.png";
import pink from "../../component/images/pink.png";
import grey from "../../component/images/grey.png";
import blue from "../../component/images/blue.jpg";
import PageCounter from "../../component/page_counter";
import { TypeDeterminer } from "../../component/content_trimer";
import emptybox from "../../component/images/emptybox.png";

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};

export default function Individual_user() {
  const [data, getData] = useState([]);
  const [data2, getData2] = useState([]);
  const location = useLocation();
  const user_id = location.state.id;
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [result2, setResult2] = useState("");
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [disable3, setDisable3] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(20);
  //console.log(user_id);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);
  let DATE = {};

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(pageCountM);
    }
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
    setDisable3(true);
  };

  const addPagec = () => {
    if (data2?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(pageCountM);
  };

  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.time;
  };

  const DateConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.date;
  };

  const Status = (value) => {
    //console.log(value);
    if (value.is_completed === true) {
      return green;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === false
    ) {
      return yellow;
    }
    if (value.is_accepted === false) {
      return grey;
    }
    if (value.is_cancelled === true) {
      return red;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === true
    ) {
      return pink;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === true
    ) {
      return blue;
    }
  };
  // background color of hover
  // const Btatus = (value) => {
  //   //console.log(value);
  //   if(value.is_completed === true){
  //     return "green"
  //   } if(value.is_accepted === true && value.is_completed === false && value.is_cancelled === false) {
  //     return "yellow"
  //   } if(value.is_accepted === false && value.is_cancelled === false){
  //     return "orange"
  //   } if (value.is_cancelled === true){
  //     return "red"
  //   }
  // }

  const Text = (value) => {
    //console.log(value);
    if (value.is_completed === true) {
      return "Delivery Completed";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === false
    ) {
      return "Delivery Accepted";
    }
    if (value.is_accepted === false) {
      return "Delivery not Accepted";
    }
    if (value.is_cancelled === true) {
      return "Delivery Cancelled";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === true
    ) {
      return "Delivery Picked Up";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === true
    ) {
      return "Delivery Paid";
    }
  };

  const Nav = (details) => {
    if (details.delivery_type === "instant") {
      navigate("/App/Instant_Delivery", { state: { id: details._id } });
    } else {
      navigate("/App/Scheduled_Delivery", { state: { id: details._id } });
    }
  };

  const fetchData = async (pageCountM) => {
    try {
      //console.log('I am here')
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/user",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            user_id: user_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const result = await response.json();
      //console.log(result.user);
      getData(result.user);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        //console.log('data gotten succesfully');
      } else {
        //console.log('somen error occurred');
      }
    } catch (error) {
      //console.log(error);
    }

    try {
      //console.log('I am here')
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delivery/user_deliveries",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            user_id: user_id,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const resultM = await response.json();
      setIsLoaded(true);
      //console.log(resultM.count);
      setResult2(resultM);
      getData2(resultM.user_deliveries);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        setDisable3(false);
        //console.log('data gotten succesfully');
      } else {
        //console.log('somen error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (!isLoaded) {
    //console.log('got here');
    //console.log(isLoaded);
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result2.msg === "Success") {
    return (
      <div className="individual-user-screen">
        <div className="individual-user-details">
          <Link to="/App/Users" className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              className="back"
            ></FontAwesomeIcon>
          </Link>
          <div className="user-details">
            <div className="user-profiless">
              <img
                src={data?.img ? data?.img : aang}
                alt="user"
                className="user-image"
              />
            </div>
            <div className="user-about">
              <div className="user-about-name">{data?.fullname}</div>
              <br></br>
              <span>Phone Number:</span> {data?.phone_no}
              <br></br>
              <span>E-mail:</span> {data?.email}
              <br></br>
              <span>Total Expenditure:</span>
              {ZeroChecker(data?.stats?.total_expenditure)}
              <br></br>
            </div>
          </div>
          <div className="user-history">
            <div className="user-instant-request">
              <div className="user-flash-display">
                <img src={insdelivery} className="deliv-icons" alt="icons" />
                <span className="flash-display">No of Instant Deliveries</span>
              </div>
              <div className="user-flash-display-no">
                {data?.stats?.total_instant_deliveries}
              </div>
            </div>

            <div className="user-scheduled-request">
              <div className="user-flash-display">
                <img src={calender} className="deliv-icons" alt="icons" />
                <span className="flash-display">
                  No of Scheduled Deliveries
                </span>
              </div>
              <div className="user-flash-display-no">
                {data?.stats?.total_scheduled_deliveries}
              </div>
            </div>
            <div className="user-cancelled-request">
              <div className="user-flash-display">
                <img src={cancelled} className="deliv-icons" alt="icons" />
                <span className="flash-display">
                  No of Cancelled Deliveries
                </span>
              </div>
              <div className="user-flash-display-no">
                {data?.stats?.total_cancelled_deliveries}
              </div>
            </div>
          </div>
          <div className="result-per-page">
            <form>
              Results per page
              <input
                className="chizys-input"
                type="number"
                value={resultPerPage}
                onChange={(e) => setResultPerPage(e.target.value)}
              />
              <button
                className="chizys-button"
                disabled={disable3}
                onClick={handleButtonClick}
              >
                Done
              </button>
            </form>
          </div>
          <div className="user-table">
            <div className="user-history-title">Delivery History</div>
            <table>
              <thead>
                <th>Parcel Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Amount</th>
                <th>Status</th>
              </thead>
              <tbody>
                {data2.map((data, i) => (
                  <tr key={i} onClick={() => Nav(data)}>
                    <td>
                      <img
                        src={TypeDeterminer(data?.delivery_type)}
                        style={{ height: "20px", width: "auto" }}
                      />
                      {data?.parcel_name}
                    </td>
                    <td>{<DateConverter value={data?.timestamp} />}</td>
                    <td>{<TimeConverter value={data?.timestamp} />}</td>
                    <td>{"₦" + data?.delivery_cost_user.toLocaleString()}</td>
                    <td className="shadow-position">
                      <img
                        className="delivery-status"
                        src={Status(data.delivery_status)}
                        alt="status"
                      />
                      <p>{Text(data?.delivery_status)}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              height: "20px",
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <div>
              {disable ? (
                <ClipLoader color={"black"} loading={disable} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                  onClick={minusPagec}
                />
              )}
            </div>
            <h6>{PageCounter(result2.count, pageCount, resultPerPage)}</h6>
            <div>
              {disable1 ? (
                <ClipLoader color={"black"} loading={disable1} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={
                    data2?.length <= resultPerPage - 1
                      ? "icon-space-less"
                      : "icon-space"
                  }
                  onClick={addPagec}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (result2.msg === "No deliveries available") {
    return (
      <div className="individual-user-screen">
        <div className="individual-user-details">
          <Link to="/App/Users" className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              className="back"
            ></FontAwesomeIcon>
          </Link>
          <div className="user-details">
            <div className="user-profiless">
              <img
                src={data?.img ? data?.img : aang}
                alt="user"
                className="user-image"
              />
            </div>
            <div className="user-about">
              <div className="user-about-name">{data?.fullname}</div>
              <br></br>
              <span>Phone Number:</span> {data?.phone_no}
              <br></br>
              <span>E-mail:</span> {data?.email}
              <br></br>
              <span>Total Expenditure:</span>
              {ZeroChecker(data?.stats?.total_expenditure)}
              <br></br>
            </div>
          </div>
          <div className="user-history">
            <div className="user-instant-request">
              <div className="user-flash-display">
                <img src={insdelivery} className="deliv-icons" alt="icons" />
                <span className="flash-display">No of Instant Deliveries</span>
              </div>
              <div className="user-flash-display-no">
                {data?.stats?.total_instant_deliveries}
              </div>
            </div>

            <div className="user-scheduled-request">
              <div className="user-flash-display">
                <img src={calender} className="deliv-icons" alt="icons" />
                <span className="flash-display">
                  No of Scheduled Deliveries
                </span>
              </div>
              <div className="user-flash-display-no">
                {data?.stats?.total_scheduled_deliveries}
              </div>
            </div>
            <div className="user-cancelled-request">
              <div className="user-flash-display">
                <img src={cancelled} className="deliv-icons" alt="icons" />
                <span className="flash-display">
                  No of Cancelled Deliveries
                </span>
              </div>
              <div className="user-flash-display-no">
                {data?.stats?.total_cancelled_deliveries}
              </div>
            </div>
          </div>
          <div className="user-table">
            <div className="found-not">
              <img src={emptybox} alt="not found" />
              <p>No delivery found for this user</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
