import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../component/css/all_deliveries.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { FaSearch } from "react-icons/fa";
import { TokenContext } from "../../component/token_context";
import mask from "../../component/images/mask.png";
import No_All_Deliveries from "../../component/notfound/No_All_Deliveries";
import green from "../../component/images/green.png";
import red from "../../component/images/red.png";
import yellow from "../../component/images/yellow.png";
import pink from "../../component/images/pink.png";
import grey from "../../component/images/grey.png";
import blue from "../../component/images/blue.jpg";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import {
  ClassNameDeterminer,
  NameTrimer,
  TypeDeterminer,
} from "../../component/content_trimer";
import ClickAwayListener from "react-click-away-listener";

export default function All_Deliveries() {
  const value = useContext(TokenContext);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState("");
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  //console.log(token);
  const [pageCount, setPageCount] = React.useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [search, setSearch] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllDeliveriesData();
  }, [isLoaded === false]);
  let DATE = {};

  const DataChecker = (data) => {
    if (data) {
      return data;
    } else {
      return "No Data Yet";
    }
  };

  const TimeConverter = (props) => {
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
    };
    return DATE.time;
  };
  const DateConverter = (props) => {
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
    };
    return DATE.date;
  };

  const Status = (value) => {
    //console.log(value);
    if (value.is_completed === true) {
      return green;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === false
    ) {
      return yellow;
    }
    if (value.is_accepted === false) {
      return grey;
    }
    if (value.is_cancelled === true) {
      return red;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === true
    ) {
      return pink;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === true
    ) {
      return blue;
    }
  };
  // background color of hover
  // const Btatus = (value) => {
  //   //console.log(value);
  //   if(value.is_completed === true){
  //     return "green"
  //   } if(value.is_accepted === true && value.is_completed === false && value.is_cancelled === false) {
  //     return "yellow"
  //   } if(value.is_accepted === false && value.is_cancelled === false){
  //     return "orange"
  //   } if (value.is_cancelled === true){
  //     return "red"
  //   }
  // }

  const Text = (value) => {
    //console.log(value);
    if (value.is_completed === true) {
      return "Delivery Completed";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === false
    ) {
      return "Delivery Accepted";
    }
    if (value.is_accepted === false) {
      return "Delivery not Accepted";
    }
    if (value.is_cancelled === true) {
      return "Delivery Cancelled";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === true
    ) {
      return "Delivery Picked Up";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === true
    ) {
      return "Delivery Paid";
    }
  };

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      if (search) {
        fetchSearchData(pageCountM);
      } else {
        fetchAllDeliveriesData(pageCountM);
      }
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    if (search) {
      fetchSearchData(pageCountM);
    } else {
      fetchAllDeliveriesData(pageCountM);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setPageCount(1);
    setIsLoaded(false);
  };
  //console.log(pageCount)

  const fetchAllDeliveriesData = async (pageCountM) => {
    //console.log(pageCount, pageCountM);
    setSearch(false);
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delivery/all_deliveries",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      // setData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      setData(resultM.all_deliveries);
      //console.log(resultM);
      //console.log(data);

      if (resultM.msg === "Success") {
        //console.log('data gotten succesfully');
        setDisable(false);
        setDisable1(false);
      } else {
        //console.log('somen error occurred');
        setDisable(false);
        setDisable1(false);
        setData("");
      }
    } catch (error) {
      //console.log(error);
      setDisable(false);
      setDisable1(false);
    }
  };

  const fetchSearchData = async (pageCountM) => {
    try {
      //console.log(searchString);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/deliveries",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            search: searchString,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      //console.log('got here');
      // setData(await response.json());
      const resultM = await response.json();
      setData(resultM.deliveries);
      setResult(resultM);
      //console.log(resultM);
      //console.log(data);

      if (resultM.msg === "Success") {
        //console.log('data gotten succesfully');
        setDisable(false);
        setDisable1(false);
      } else {
        setData("");
        //console.log('somen error occurred');
        setDisable(false);
        setDisable1(false);
      }
    } catch (error) {
      //console.log(error);
      setDisable(false);
      setDisable1(false);
    }
  };

  function DeliveriesApp() {
    const [popUpMenu, setPopUpMenu] = React.useState(false);
    return (
      <div className="deliveries-App">
        <button
          onClick={() => setPopUpMenu(!popUpMenu)}
          className="deliveries-boom"
        >
          All Deliveries <img src={mask} alt="" className="mask" />
        </button>
        {popUpMenu && (
          <ClickAwayListener onClickAway={() => setPopUpMenu(false)}>
            {PopUpMenu()}
          </ClickAwayListener>
        )}
      </div>
    );
  }

  function PopUpMenu() {
    return (
      <ul className="all-deliveries-drop-down">
        <li onClick={() => navigate("/App/All_Deliveries")}>All Deliveries</li>
        <li onClick={() => navigate("/App/Instant_Deliveries")}>
          Instant Deliveries
        </li>
        <li onClick={() => navigate("/App/Scheduled_Deliveries")}>
          Scheduled Deliveries
        </li>
        <li onClick={() => navigate("/App/Cancelled_Deliveries")}>
          Cancelled Deliveries
        </li>
      </ul>
    );
  }

  const Nav = (details) => {
    //console.log(details, 'details');
    if (details.delivery_type === "instant") {
      navigate("/App/Instant_Delivery", { state: { id: details._id } });
    } else {
      navigate("/App/Scheduled_Delivery", { state: { id: details._id } });
    }
  };

  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1 className="loading-pages">
        {" "}
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (data?.length === 0) {
    //console.log('and here');
    return <No_All_Deliveries />;
  } else if (data?.length > 0) {
    //console.log('data gotten succesfully');

    return (
      <div className="all-deliveries">
        <div className="all-deliveries-properties">
          <Link to="/App/Dashboard" className="back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              className="back"
            ></FontAwesomeIcon>
          </Link>
          <div className="deliveries-top">
            <h1 className="all-deliveries-title">All Deliveries</h1>
            <div>
              <div>
                <DeliveriesApp />
              </div>
            </div>
          </div>

          <div className="all-deliveries-table">
            <div className="users-search-box-container">
              <div className="main-search-box-container">
                <input
                  type="text"
                  placeholder="Search Deliveries"
                  className="search-box"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                <button className="delivery-searching">
                  <FaSearch
                    onClick={() => {
                      fetchSearchData();
                      setSearch(true);
                      setPageCount(1);
                    }}
                  />
                </button>
              </div>
              <div style={{ visibility: "hidden" }}>
                <Link to="/App/Blocked_Users">
                  {" "}
                  <button className="delivery-agent-block">
                    View blocked List
                  </button>
                </Link>
              </div>
            </div>

            <div className="result-per-page">
              <form>
                Results per page
                <input
                  className="chizys-input"
                  type="number"
                  value={resultPerPage}
                  onChange={(e) => setResultPerPage(e.target.value)}
                />
                <button className="chizys-button" onClick={handleButtonClick}>
                  Done
                </button>
              </form>
            </div>
            <table>
              <th>Delivery ID</th>
              <th>Date/Time</th>
              <th>Clients name</th>
              <th>Clients Phone No</th>
              <th>Agents Name</th>
              <th>Status</th>
              <tbody>
                {data?.map((item, i) => (
                  <tr key={i} onClick={() => Nav(item)}>
                    <td>
                      <img
                        src={TypeDeterminer(item?.delivery_type)}
                        style={{ height: "20px", width: "auto" }}
                      />
                      {item?.parcel_code}
                    </td>
                    <td>
                      {<DateConverter value={item?.timestamp} />} {<br />}{" "}
                      {<TimeConverter value={item?.timestamp} />}
                    </td>
                    <td className={ClassNameDeterminer(item?.sender_fullname)}>
                      {NameTrimer(DataChecker(item?.sender_fullname))}
                      <p style={{ display: "none" }}>
                        {DataChecker(item?.sender_fullname)}
                      </p>
                    </td>
                    <td>{"+234" + item?.sender_phone_no}</td>
                    <td
                      className={ClassNameDeterminer(item?.delivery_agent_name)}
                    >
                      {NameTrimer(DataChecker(item?.delivery_agent_name))}
                      <p style={{ display: "none" }}>
                        {DataChecker(item?.delivery_agent_name)}
                      </p>
                    </td>
                    <td className="shadow-position">
                      <img
                        className="delivery-status"
                        src={Status(item?.delivery_status)}
                        alt="status"
                      />
                      <p>{Text(item?.delivery_status)}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              height: "20px",
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <div>
              {disable ? (
                <ClipLoader color={"black"} loading={disable} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                  onClick={minusPagec}
                />
              )}
            </div>
            <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
            <div>
              {disable1 ? (
                <ClipLoader color={"black"} loading={disable1} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={
                    data?.length <= resultPerPage - 1
                      ? "icon-space-less"
                      : "icon-space"
                  }
                  onClick={addPagec}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data?.length === 0) {
    //console.log('and here');
    return <No_All_Deliveries />;
  }
}
