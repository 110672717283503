import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../css/edit_profile.css";
import pickloadonlylogo from "../images/pickloadonlylogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";

export default function Profile() {
  const [data, setData] = useState([]);
  const location = useLocation();
  //console.log(location);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const navigate = useNavigate();

  useEffect(() => {
    ViewProfile();
  }, [isLoaded === false]);

  const ViewProfile = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_profile/view_profile",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const resultM = await response.json();
      setResult(resultM);
      //console.log(resultM);
      setData(resultM.admin);
      setIsLoaded(true);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        //console.log('data gotten succesfully');
      } else {
        //console.log('somen error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (!isLoaded) {
    //console.log('got here');
    //console.log(isLoaded);
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result?.msg === "Success") {
    return (
      <div className="edit-profile">
        <div className="edit-profile-props">
          <div className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="align-main-admin-profile">
            <h2 className="edit-profile-title">Admin profile</h2>
            <div className="edit-profile-picture">
              <div className="edit-profile-main">
                <img
                  src={data?.img ? data?.img : pickloadonlylogo}
                  alt=""
                  className="admin-change-pic"
                />
              </div>
            </div>
            <div className="role-profile">Role</div>
            <div className="rank">{data?.role[0]}</div>
            <div className="admin-number">
              <div className="admin-phone-number-props">
                <label className="admin-no">Full Name</label>
                <div className="admin-number-main">
                  <div className="admin-number-props">
                    <div className="admin-name-styling">{data?.fullname}</div>
                  </div>
                  <Link to="/App/Change_Name" className="change-edit">
                    <div>change</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="admin-number">
              <div className="admin-phone-number-props">
                <label className="admin-no">Email</label>
                <div className="admin-number-main">
                  <div className="admin-number-props">
                    <div className="admin-name-styling">{data?.email}</div>
                  </div>
                  <p style={{ visibility: "hidden" }} className="change-edit">
                    change
                  </p>
                </div>
              </div>
            </div>
            <div className="admin-number">
              <div className="admin-phone-number-props">
                <label className="admin-no">Phone Number</label>
                <div className="admin-number-main">
                  <div className="admin-number-props">
                    <div className="admin-name-styling">{data?.phone_no}</div>
                  </div>
                  <Link to="/App/Change_Phone_Number" className="change-edit">
                    <div>change</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    //console.log(result)
    //console.log('some error occurred')
  }
}
