import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/popup.css";

export default function Delete_Administrator() {
  const navigate = useNavigate();
  const location = useLocation();
  const admin_id = location.state.id;
  const [data, getData] = useState([]);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const DeleteAdmin = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/master_admin/delete_admin",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            admin_id: admin_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const result = await response.json();
      //console.log(result);
      // getData(result.delivery_agent);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        //console.log('data gotten succesfully');
      } else {
        //console.log('somen error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };
  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Delete Administrator Confirmation
            </div>
            <div className="popup-rematched">
              Are you sure you want to Delete <br></br>this Administrator?
            </div>
          </div>
          <div className="popup-dialog-row">
            <div
              className="popup-dialog"
              onClick={() => navigate("/App/Administrator")}
            >
              No
            </div>
            <div className="middle-line">
              <hr className="popup-hr"></hr>
            </div>
            <div
              className="popup-dialog1"
              onClick={() => {
                DeleteAdmin();
                navigate("/App/Administrator");
              }}
            >
              Yes
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
