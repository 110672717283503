import React, { useState } from 'react';
import "./referral.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import redImg from "../../component/images/verifyred.png";
import greenImg from "../../component/images/verifygreen.png";
import Modal from 'react-modal';
import downImg from "../../component/images/down.png";
import green from "../../component/images/verifygreen.png";
import cancel from '../../component/images/close.png';
import call from "../../component/images/refcall.png";


const AgentReferral = () => {

    const [selectedReward, setSelectedReward] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setDropdownOpen] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const agents = [
        { date: 'Jan 1st', progress: 2, target: 5 },
        { date: 'Jan 2nd', progress: 10, target: 10 },
        { date: 'Jan 2nd', progress: 10, target: 10 },
        { date: 'Jan 2nd', progress: 10, target: 10 },
        { date: 'Jan 2nd', progress: 10, target: 10 },
        { date: 'Jan 2nd', progress: 10, target: 10 },
        { date: 'Jan 2nd', progress: 10, target: 10 },
    ];

    const toggleDropdown = (index) => {
        setDropdownOpen(isDropdownOpen === index ? null : index);
    };

    const handleRewardSelection = (reward) => {
        setSelectedReward(reward);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div className='referral'>
            <div className='referal-main'>
                <div className='referal-user-top'>
                    <Link to="/App/referrals" className="ref-back">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="ref-back"
                        ></FontAwesomeIcon>
                    </Link>
                    <p><span>Referrer:</span> Go kada</p>
                    <div className='ref-phone'>
                        <h1>Phone:</h1>
                        <p>0912322009</p>
                        <img
                            src={call}
                            width="32px"
                            height="32px"
                            alt='icon'
                        />
                    </div>
                </div>
                <div className='agent-referal-main'>
                    <div className='agent-referal-ctn'>
                        <div className='agent-ref-title'>
                            <h1>Week 1</h1>
                            <div className='agent-ref-hit'>
                                <img
                                    src={green}
                                    width='30px'
                                    height="30px"
                                    alt='icon'
                                />
                                <p>150/100</p>
                            </div>
                        </div>
                        <div className='agent-ref-props'>
                            {agents.map((agent, index) => (
                                <div className='agent-ref-contents' key={index}>
                                    <img
                                        src={agent.progress >= 10 ? redImg : greenImg}
                                        width="36px"
                                        height="36px"
                                        alt='icon'
                                    />
                                    <div className='agent-ref-date'>
                                        <h1>{agent.date}</h1>
                                        <p>12/11</p>
                                    </div>
                                    <span style={{ background: `linear-gradient(to right, rgba(26, 168, 3, 1) ${(agent.progress / agent.target) * 100}%, rgba(191, 246, 182, 1) ${(agent.progress / agent.target) * 100}%)` }}></span>
                                    <p>({agent.progress}/{agent.target})</p>
                                    <button className='use-ref-btn' onClick={() => toggleDropdown(index)}>
                                        None
                                        <img
                                            src={downImg}
                                            alt="Increase"
                                            width="11px"
                                            height="11px"
                                        />
                                    </button>
                                    {isDropdownOpen === index && (
                                        <div className="dropdown1">
                                            <div className="dropdown1-header">
                                                <img
                                                    src={cancel}
                                                    width="15px"
                                                    height="15px"
                                                    alt='Close dropdown'
                                                    onClick={() => toggleDropdown(null)} // Close the dropdown
                                                />
                                            </div>
                                            {['None', 'Airtime', 'Cash', 'Data'].map(option => (
                                                <div key={option} className="dropdown1-option">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOption === option}
                                                        onChange={() => setSelectedOption(option)}
                                                        onClick={() => handleRewardSelection(selectedOption)}
                                                    />
                                                    <label>{option}</label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <Modal
                                        className="reward-modal"
                                        overlayClassName="reward-overlay"
                                        isOpen={modalOpen}
                                        onRequestClose={closeModal}
                                    >
                                        <h1>Confirm Reward</h1>
                                        <h2>Are you sure you want to reward Promise Osagie with “{selectedReward}” for this 5 set of referees</h2>
                                        <button className='modal-btn' onClick={closeModal}>No</button>
                                        <button className='modal-btn1' onClick={() => alert(`You have chosen to buy ${selectedReward}`)}>
                                            Yes
                                        </button>
                                    </Modal>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='agent-referal-ctn'>
                        <div className='agent-ref-title'>
                            <h1>Week 2</h1>
                            <div className='agent-ref-hit'>
                                <img
                                    src={green}
                                    width='30px'
                                    height="30px"
                                    alt='icon'
                                />
                                <p>150/100</p>
                            </div>
                        </div>
                        <div className='agent-ref-props'>
                            {agents.map((agent, index) => (
                                <div className='agent-ref-contents' key={index}>
                                    <img
                                        src={agent.progress >= 10 ? redImg : greenImg}
                                        width="36px"
                                        height="36px"
                                        alt='icon'
                                    />
                                    <div className='agent-ref-date'>
                                        <h1>{agent.date}</h1>
                                        <p>12/11</p>
                                    </div>
                                    <span style={{ background: `linear-gradient(to right, rgba(26, 168, 3, 1) ${(agent.progress / agent.target) * 100}%, rgba(191, 246, 182, 1) ${(agent.progress / agent.target) * 100}%)` }}></span>
                                    <p>({agent.progress}/{agent.target})</p>
                                    <button className='use-ref-btn' onClick={() => toggleDropdown(index)}>
                                        None
                                        <img
                                            src={downImg}
                                            alt="Increase"
                                            width="11px"
                                            height="11px"
                                        />
                                    </button>
                                    {isDropdownOpen === index && (
                                        <div className="dropdown1">
                                            <div className="dropdown1-header">
                                                <img
                                                    src={cancel}
                                                    width="15px"
                                                    height="15px"
                                                    alt='Close dropdown'
                                                    onClick={() => toggleDropdown(null)} // Close the dropdown
                                                />
                                            </div>
                                            {['None', 'Airtime', 'Cash', 'Data'].map(option => (
                                                <div key={option} className="dropdown1-option">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOption === option}
                                                        onChange={() => setSelectedOption(option)}
                                                        onClick={() => handleRewardSelection(selectedOption)}
                                                    />
                                                    <label>{option}</label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <Modal
                                        className="reward-modal"
                                        overlayClassName="reward-overlay"
                                        isOpen={modalOpen}
                                        onRequestClose={closeModal}
                                    >
                                        <h1>Confirm Reward</h1>
                                        <h2>Are you sure you want to reward Promise Osagie with “{selectedReward}” for this 5 set of referees</h2>
                                        <button className='modal-btn' onClick={closeModal}>No</button>
                                        <button className='modal-btn1' onClick={() => alert(`You have chosen to buy ${selectedReward}`)}>
                                            Yes
                                        </button>
                                    </Modal>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='agent-referal-ctn'>
                        <div className='agent-ref-title'>
                            <h1>week 1</h1>
                            <div className='agent-ref-hit'>
                                <img
                                    src={green}
                                    width='30px'
                                    height="30px"
                                    alt='icon'
                                />
                                <p>150/100</p>
                            </div>
                        </div>
                        <div className='agent-ref-props'>
                            {agents.map((agent, index) => (
                                <div className='agent-ref-contents' key={index}>
                                    <img
                                        src={agent.progress >= 10 ? redImg : greenImg}
                                        width="36px"
                                        height="36px"
                                        alt='icon'
                                    />
                                    <div className='agent-ref-date'>
                                        <h1>{agent.date}</h1>
                                        <p>12/11</p>
                                    </div>
                                    <span style={{ background: `linear-gradient(to right, rgba(26, 168, 3, 1) ${(agent.progress / agent.target) * 100}%, rgba(191, 246, 182, 1) ${(agent.progress / agent.target) * 100}%)` }}></span>
                                    <p>({agent.progress}/{agent.target})</p>
                                    <button className='use-ref-btn' onClick={() => toggleDropdown(index)}>
                                        None
                                        <img
                                            src={downImg}
                                            alt="Increase"
                                            width="11px"
                                            height="11px"
                                        />
                                    </button>
                                    {isDropdownOpen === index && (
                                        <div className="dropdown1">
                                            <div className="dropdown1-header">
                                                <img
                                                    src={cancel}
                                                    width="15px"
                                                    height="15px"
                                                    alt='Close dropdown'
                                                    onClick={() => toggleDropdown(null)} // Close the dropdown
                                                />
                                            </div>
                                            {['None', 'Airtime', 'Cash', 'Data'].map(option => (
                                                <div key={option} className="dropdown1-option">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOption === option}
                                                        onChange={() => setSelectedOption(option)}
                                                        onClick={() => handleRewardSelection(selectedOption)}
                                                    />
                                                    <label>{option}</label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <Modal
                                        className="reward-modal"
                                        overlayClassName="reward-overlay"
                                        isOpen={modalOpen}
                                        onRequestClose={closeModal}
                                    >
                                        <h1>Confirm Reward</h1>
                                        <h2>Are you sure you want to reward Promise Osagie with “{selectedReward}” for this 5 set of referees</h2>
                                        <button className='modal-btn' onClick={closeModal}>No</button>
                                        <button className='modal-btn1' onClick={() => alert(`You have chosen to buy ${selectedReward}`)}>
                                            Yes
                                        </button>
                                    </Modal>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='agent-referal-ctn'>
                        <div className='agent-ref-title'>
                            <h1>Week  </h1>
                            <div className='agent-ref-hit'>
                                <img
                                    src={green}
                                    width='30px'
                                    height="30px"
                                    alt='icon'
                                />
                                <p>150/100</p>
                            </div>
                        </div>
                        <div className='agent-ref-props'>
                            {agents.map((agent, index) => (
                                <div className='agent-ref-contents' key={index}>
                                    <img
                                        src={agent.progress >= 10 ? redImg : greenImg}
                                        width="36px"
                                        height="36px"
                                        alt='icon'
                                    />
                                    <div className='agent-ref-date'>
                                        <h1>{agent.date}</h1>
                                        <p>12/11</p>
                                    </div>
                                    <span style={{ background: `linear-gradient(to right, rgba(26, 168, 3, 1) ${(agent.progress / agent.target) * 100}%, rgba(191, 246, 182, 1) ${(agent.progress / agent.target) * 100}%)` }}></span>
                                    <p>({agent.progress}/{agent.target})</p>
                                    <button className='use-ref-btn' onClick={() => toggleDropdown(index)}>
                                        None
                                        <img
                                            src={downImg}
                                            alt="Increase"
                                            width="11px"
                                            height="11px"
                                        />
                                    </button>
                                    {isDropdownOpen === index && (
                                        <div className="dropdown1">
                                            <div className="dropdown1-header">
                                                <img
                                                    src={cancel}
                                                    width="15px"
                                                    height="15px"
                                                    alt='Close dropdown'
                                                    onClick={() => toggleDropdown(null)} // Close the dropdown
                                                />
                                            </div>
                                            {['None', 'Airtime', 'Cash', 'Data'].map(option => (
                                                <div key={option} className="dropdown1-option">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOption === option}
                                                        onChange={() => setSelectedOption(option)}
                                                        onClick={() => handleRewardSelection(selectedOption)}
                                                    />
                                                    <label>{option}</label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <Modal
                                        className="reward-modal"
                                        overlayClassName="reward-overlay"
                                        isOpen={modalOpen}
                                        onRequestClose={closeModal}
                                    >
                                        <h1>Confirm Reward</h1>
                                        <h2>Are you sure you want to reward Promise Osagie with “{selectedReward}” for this 5 set of referees</h2>
                                        <button className='modal-btn' onClick={closeModal}>No</button>
                                        <button className='modal-btn1' onClick={() => alert(`You have chosen to buy ${selectedReward}`)}>
                                            Yes
                                        </button>
                                    </Modal>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentReferral
