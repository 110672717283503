import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/popup.css";

export default function Delete_Delivery_Agent() {
  const navigate = useNavigate();
  const location = useLocation();
  const delivery_agent_id = location.state.details.id;
  const delivery_agent_name = location.state.details.name;
  const delivery_agent_code = location.state.details.code;
  const [data, getData] = useState([]);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const DeleteDeliveryAgent = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delete/delete_delivery_agent",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const result = await response.json();
      //console.log(result.delivery_agent);
      getData(result.delivery_agent);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        //console.log('data gotten succesfully');
      } else {
        //console.log('somen error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Delete agent Confirmation
            </div>
            <div className="popup-rematched">
              Are you sure you want to delete Delivery agent<br></br>{" "}
              {delivery_agent_name} with delivery agent ID<br></br>
              {delivery_agent_code}?
            </div>
          </div>
          <div className="popup-dialog-row">
            <div
              className="popup-dialog"
              onClick={() => navigate("/App/Delivery_Agents")}
            >
              No
            </div>
            <div className="middle-line">
              <hr className="popup-hr"></hr>
            </div>
            <div
              className="popup-dialog1"
              onClick={() => {
                DeleteDeliveryAgent();
                navigate("/App/Delivery_Agents");
              }}
            >
              Yes
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
