import React, { useState } from 'react';
import "./referral.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import down from "../../component/images/down.png";


const UserReferral = () => {

  const data = [
    { name: 'Jude Ozoh', phone: '', deliveries: 23 },
    { name: 'john igbe   Smith', phone: '', deliveries: 22 },
    { name: 'Mari tola', phone: '', deliveries: 23 },
    { name: 'Jude Ozoh', phone: '', deliveries: 23 },
    { name: 'John igbe', phone: '', deliveries: 22 },
    { name: 'Jude Ozoh', phone: '', deliveries: 14 },
    { name: 'john igbe   Smith', phone: '', deliveries: 14 },
    { name: 'Mari tola', phone: '', deliveries: 13 },
    { name: 'Jude Ozoh', phone: '', deliveries: 13 },
    { name: 'John igbe', phone: '', deliveries: 12 },

  ];

  const rewards = ['None', 'Airtime', 'Cash', 'Data'];


  const [dropdownOpen, setDropdownOpen] = useState(Array(Math.ceil(data.length / 5)).fill(false));
  const [confirmationOpen, setConfirmationOpen] = useState(Array(Math.ceil(data.length / 5)).fill(false));
  const [selectedReward, setselectedReward] = useState('None');

  const handleNoneClick = (index) => {
    const updatedDropdownOpen = [...dropdownOpen];
    updatedDropdownOpen[index] = !updatedDropdownOpen[index];
    setDropdownOpen(updatedDropdownOpen);
  };

  const handleRewardChange = (reward, index) => {
    setselectedReward(reward);

    const updatedDropdownOpen = [...dropdownOpen];
    updatedDropdownOpen[index] = false;
    setDropdownOpen(updatedDropdownOpen);

    const updatedConfirmationOpen = [...confirmationOpen];
    updatedConfirmationOpen[index] = true;
    setConfirmationOpen(updatedConfirmationOpen);
  };

  const closeModal = (index) => {
    const updatedConfirmationOpen = [...confirmationOpen];
    updatedConfirmationOpen[index] = false;
    setConfirmationOpen(updatedConfirmationOpen);
  };

  const [isOn, setIsOn] = useState(false);

  const handleClick = () => {
    setIsOn(!isOn); 
  };


  return (
    <div className='referral'>
      <div className='referal-main'>
        <div className='referal-user-top'>
          <Link to="/App/referrals" className="ref-back">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              className="ref-back"
            ></FontAwesomeIcon>
          </Link>
          <p><span>Referrer:</span> Osagie Promise</p>
          <div className='ref-phone'>
            <h1>Phone:</h1>
            <p>0912322009</p>
            <span>w</span>
          </div>
        </div>
        <div className='referal-user-header'>
          <h1>REFEREE LIST</h1>
          <div className='hide-refree-btns'>
            <p>Hide referee that <br /> have been rewarded</p>
            <div className="refer-switch-container" onClick={handleClick}>
              <div className={`refer-slider ${isOn ? 'on' : 'off'}`} />
            </div>
          </div>
        </div>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Completed Deliveries</th>
                <th>Reward Status</th>
                <th>Reward Type</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => {
                const rewardStatus = row.deliveries > 20 ? 'Pending Reward' : 'Target Not Met';
                const showRewardType = index % 5 === 0;
                const groupIndex = Math.floor(index / 5);
                return (
                  <tr key={index} className={(index + 1) % 5 === 0 ? 'black-border' : 'gray-border'}>
                    <td>{row.name}</td>
                    <td>{row.deliveries}</td>
                    <td>{rewardStatus}</td>
                    {showRewardType ? (
                      <td rowSpan="5" className='user-ref-option'>
                        <button className='use-ref-btn' onClick={() => handleNoneClick(groupIndex)}>
                          None
                          <img
                            src={down}
                            alt="Increase"
                            width="11px"
                            height="11px"
                          />
                        </button>
                        {dropdownOpen[groupIndex] && (
                          <div className="dropdown">
                            {rewards.map((reward) => (
                              <div key={reward}>
                                <input
                                  type="checkbox"
                                  checked={selectedReward === reward}
                                  onChange={() => handleRewardChange(reward, groupIndex)}
                                />
                                <label>{reward}</label>
                              </div>
                            ))}
                          </div>
                        )}

                        <Modal
                          isOpen={confirmationOpen[groupIndex]}
                          onRequestClose={() => closeModal(groupIndex)}
                          className="reward-modal"
                          overlayClassName="reward-overlay"
                        >
                          <h1>Confirm Reward</h1>
                          <h2>Are you sure you want to reward
                            Promise Osagie with “{selectedReward}” for this 5 set of referees</h2>
                          <button className='modal-btn' onClick={() => closeModal(groupIndex)}>No</button>
                          <button className='modal-btn1' onClick={() => alert(`You have chosen to buy ${selectedReward}`)}>
                            Yes
                          </button>
                        </Modal>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
      </div>
    </div>
  );
}

export default UserReferral;
